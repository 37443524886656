<template>
  <div class="approval-record">
    <div class="approval">
      <div class="content" v-for="(item, i) in list" :key="i">
        <div class="content-header">{{ sliceName(item.userName) }}</div>
        <div class="content-box">
          <div class="top">
            {{ item.operation }}
          </div>
          <div class="bottom">
            <div>{{ item.userName }}</div>
            <div>
              <span>{{ item.createTime }}</span>
              <span class="time">{{ item.createTimeText }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'approval-record',
  props: {
    typeId: Number || String,
    type: String
  },
  components: {},
  data() {
    return {
      list: []
    }
  },
  computed: {},
  methods: {
    // 获取审批的记录列表
    getRecordList() {
      this.$axios
        .get(
          `${this.$base}/management/approve/operation/record?type=${
            this.type
          }&typeId=${this.typeId}`
        )
        .then(res => {
          const { code, data } = res.data
          if (code === 200) {
            data.map(item => {
              if (item.createTime) {
                // let replacetime = item.createTime.replace(/-/g, '/')
                const timeArr = item.createTime.split(' ')
                const d = timeArr[0].split('-')
                const t = timeArr[1].split(':')
                if (t.length < 3) {
                  t.push('00')
                }
                const date = new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2])
                const dateTimeStamp = date.getTime()
                item.createTimeText = this.getDateDiff(dateTimeStamp)
              }
              this.list.push(item)
              this.$emit('nickname', this.list[this.list.length - 1])
            })
          }
        })
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    },

    sliceName(userName) {
      return userName.slice(userName.length - 2)
    }
  },
  created() {
    this.getRecordList()
  },
  mounted() {}
}
</script>

<style lang="stylus">
.approval-record
  position relative
  & .approval
    & .content
      display flex
      flex-direction row
      & .content-box
        border-left 2px solid #cccccc
        padding 0px 0px 40px 20px
        box-sizing border-box
        position relative
        left -14px
        z-index -1
        flex 1
      &:last-child
        & .content-box
          border-left 0 solid #cccccc
      & .content-header
        width 35px
        height 35px
        border-radius 20px
        background #008AFF
        text-align center
        line-height 35px
        color #FFF
      & .top
        font-size 14px
      & .bottom
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        font-size 12px
        color #cccccc
        width 100%
        & .time
          color #000000
          margin-left 10px
</style>
