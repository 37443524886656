var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "approval-record" }, [
    _c(
      "div",
      { staticClass: "approval" },
      _vm._l(_vm.list, function (item, i) {
        return _c("div", { key: i, staticClass: "content" }, [
          _c("div", { staticClass: "content-header" }, [
            _vm._v(_vm._s(_vm.sliceName(item.userName))),
          ]),
          _c("div", { staticClass: "content-box" }, [
            _c("div", { staticClass: "top" }, [
              _vm._v(" " + _vm._s(item.operation) + " "),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c("div", [_vm._v(_vm._s(item.userName))]),
              _c("div", [
                _c("span", [_vm._v(_vm._s(item.createTime))]),
                _c("span", { staticClass: "time" }, [
                  _vm._v(_vm._s(item.createTimeText)),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }